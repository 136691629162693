import type { IAuthorization, IReturn, ISettlement } from '~/types/authorization.types'
import type { ICardTransaction, ICardTransactionRequestParams, IRewardRequestParams } from '~/types/cardTransaction.types'
import type { IOliveError } from '~/types/errors.types'
import type { ITotalRewardsStatus } from '~/types/offer.types'
import type { IPlatformResponse } from '~/types/platform.types'
import type { ITransactionByMember, ITransactionTotalsByMember, ITransactionTotalsByMemberSummary, ITransactionTotalsBySupplierType } from '~/types/transaction.types'
import Decimal from 'decimal.js'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { HTTP_METHOD } from '~/types/common.types'
import { OFFER_REWARD_STATUS } from '~/types/offer.types'
import { getOfferRedemptionStatusTitle, OFFER_REDEMPTION_STATUS } from '~/types/offerRedemptions.types'

export const useCardTransactionsStore = defineStore('cardTransactions', () => {
  const oliveBaseUrl = '/transactions'

  // #region state
  const transactions = ref<ICardTransaction[]>([])
  const memberTransactions = ref<ICardTransaction[]>([])
  const totalRewards = ref<ITotalRewardsStatus[]>([])
  const totalBySupplierType = ref<ITransactionTotalsBySupplierType[]>([])
  const totalByMember = ref<ITransactionTotalsByMember[]>([])
  const transactionIdsCurrentPage = ref<string[]>([])
  const totalByMembersCurrentPage = ref<string[]>([])
  const numberOfTransactions = ref<number>(0)
  const numberOfTotalsByMember = ref<number>(0)
  const error = ref<IOliveError>(getOliveError())
  const isLoading = ref(false)
  const isLoadingRewards = ref(false)
  const filter = ref<ICardTransactionRequestParams>({})

  const latestRequestTime = ref()
  // #endregion

  const getTransactionsCurrentPage = computed (() => {
    return transactions.value.filter(t => transactionIdsCurrentPage.value.includes(t.id))
  })

  const getTotalByMembersCurrentPage = computed (() => {
    return totalByMember.value.filter(t => totalByMembersCurrentPage.value.includes(t.memberId)).map((t) => {
      const owedToMemberAmountTotal = new Decimal(t.owedToMemberAmountTotal).toNumber()
      const pendingDisbursementToClient = new Decimal(t.distributedToOliveOwedToMemberAmountTotal).toNumber()
      const toBePaidByClientToMember = new Decimal(t.distributedToMemberDistributorOwedToMemberAmountTotal).toNumber()
      const pendingMerchantFundingOwedToMemberAmountTotal = new Decimal(t.pendingMerchantFundingOwedToMemberAmountTotal)
      const pendingTransferToPublisherOwedToMemberAmountTotal = new Decimal(t.pendingTransferToPublisherOwedToMemberAmountTotal)
      const distributedToPublisherOwedToMemberAmountTotal = new Decimal(t.distributedToPublisherOwedToMemberAmountTotal)
      const confirmedTotal = pendingMerchantFundingOwedToMemberAmountTotal.plus(pendingTransferToPublisherOwedToMemberAmountTotal).plus(distributedToPublisherOwedToMemberAmountTotal).toNumber()
      return {
        memberId: t.memberId,
        extMemberId: t.extMemberId,
        owedToMemberAmountTotal,
        pendingDisbursementToClient,
        toBePaidByClientToMember,
        confirmedTotal,
      } as ITransactionTotalsByMemberSummary
    })
  })

  const getTotalByMembersTransactionsCurrentPage = computed (() => {
    return (memberId: string, clientId: string) => {
      return memberTransactions.value.filter(t => t.memberId === memberId && t.clientId === clientId).map((t) => {
        const owedToMemberAmount = t.reward?.owedToMemberAmount ? new Decimal(t.reward?.owedToMemberAmount).toNumber() : undefined
        return {
          transactionId: t.id,
          cardLast4Digits: t.cardLast4Digits,
          cardId: t.cardId,
          brandId: t.brandId,
          offerId: t.reward?.offerId,
          purchaseDate: t.purchaseDateTime,
          confirmedDate: t.reward?.confirmedDate,
          owedToMemberAmountTotal: owedToMemberAmount,
          confirmedTotal: (t.reward?.status === OFFER_REWARD_STATUS.PENDING_MERCHANT_FUNDING
            || t.reward?.status === OFFER_REWARD_STATUS.PENDING_TRANSFER_TO_PUBLISHER
            || t.reward?.status === OFFER_REWARD_STATUS.DISTRIBUTED_TO_PUBLISHER)
            ? owedToMemberAmount
            : undefined,
          pendingDisbursementToClient: t.reward?.status === OFFER_REWARD_STATUS.DISTRIBUTED_TO_OLIVE
            ? owedToMemberAmount
            : undefined,
          toBePaidByClientToMember: t.reward?.status === OFFER_REWARD_STATUS.DISTRIBUTED_TO_MEMBER_DISTRIBUTOR
            ? owedToMemberAmount
            : undefined,
          rewardStatus: t.reward?.status,
          rewardAmount: t.reward.amount,
        } as ITransactionByMember
      })
    }
  })

  const getFormattedRewardStatus = computed(() => {
    return (rewardStatus: OFFER_REWARD_STATUS) => {
      switch (rewardStatus) {
        case OFFER_REWARD_STATUS.PENDING_REVIEW:
        case OFFER_REWARD_STATUS.EARNED_PENDING_SETTLEMENT:
          return { title: getOfferRedemptionStatusTitle(OFFER_REDEMPTION_STATUS.EARNED), color: 'grey-lighten-2' }
        case OFFER_REWARD_STATUS.PENDING_MERCHANT_APPROVAL:
          return { title: getOfferRedemptionStatusTitle(OFFER_REDEMPTION_STATUS.SUBMITTED_TO_MERCHANT), color: 'grey-lighten-2' }
        case OFFER_REWARD_STATUS.PENDING_MERCHANT_FUNDING:
          return { title: getOfferRedemptionStatusTitle(OFFER_REDEMPTION_STATUS.CONFIRMED), color: 'nyanza' }
        case OFFER_REWARD_STATUS.PENDING_TRANSFER_TO_PUBLISHER:
        case OFFER_REWARD_STATUS.DISTRIBUTED_TO_PUBLISHER:
        case OFFER_REWARD_STATUS.DISTRIBUTED_TO_OLIVE:
          return { title: getOfferRedemptionStatusTitle(OFFER_REDEMPTION_STATUS.FUNDED), color: 'nyanza' }
        case OFFER_REWARD_STATUS.DISTRIBUTED_TO_MEMBER_DISTRIBUTOR:
          return { title: getOfferRedemptionStatusTitle(OFFER_REDEMPTION_STATUS.DISTRIBUTED_TO_ACCOUNT), color: 'primary-lighten-2' }
        case OFFER_REWARD_STATUS.REJECTED:
          return { title: getOfferRedemptionStatusTitle(OFFER_REDEMPTION_STATUS.REJECTED), color: 'red-lighten-2' }
        case OFFER_REWARD_STATUS.DISTRIBUTION_TO_MEMBER_FAILED:
          return { title: 'Distribution Failed', color: 'red-lighten-2' }
        case OFFER_REWARD_STATUS.TRIGGER_ACCUMULATING_EXPIRED:
          return { title: 'Expired', color: 'red-lighten-2' }
        default:
          return { title: 'Error: Unmapped', color: 'grey-lighten-2' }
      }
    }
  })
  // #endregion

  // #region getters
  const getTransactionById = computed(() => {
    return (id: string) => transactions.value.find(t => t.id === id)
  })

  // #endregion

  // #region actions
  const loadTransactions = async (params?: ICardTransactionRequestParams, isMemberTransactions = false) => {
    const {
      response,
      error: transactionsError,
      run: loadTransactions,
    } = useOliveAPI<IPlatformResponse<ICardTransaction>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Error loading transactions',
    })
    isLoading.value = true
    error.value = transactionsError.value

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadTransactions()

    if (response.value?.items && !transactionsError.value.hasError) {
      transactions.value = useArrayUnique([...response.value.items, ...transactions.value], (a, b) => a.id === b.id).value
      if (isMemberTransactions)
        memberTransactions.value = useArrayUnique([...response.value.items, ...memberTransactions.value], (a, b) => a.id === b.id).value.sort((a, b) => new Date(b.purchaseDateTime).getTime() - new Date(a.purchaseDateTime).getTime())

      if (latestRequestTime.value === currentRequestDateTime) {
        transactionIdsCurrentPage.value = [...response.value.items.map(m => m.id)]
        numberOfTransactions.value = response.value.totalNumberOfRecords
      }
    }
    else { error.value = transactionsError.value }

    isLoading.value = false
  }

  const loadTransaction = async (id: string, forceApiCall = false) => {
    if (!forceApiCall && getTransactionById.value(id))
      return

    const {
      response,
      error: transactionError,
      run: loadTransaction,
    } = useOliveAPI<ICardTransaction>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${id}`,
      errorMessage: 'Error loading transaction',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadTransaction()

    if (response.value && !transactionError.value.hasError)
      transactions.value = useArrayUnique([response.value, ...transactions.value], (a, b) => a.id === b.id).value
    else
      error.value = transactionError.value

    isLoading.value = false
  }

  const loadTransactionTotalsRewardStatus = async (params?: ICardTransactionRequestParams) => {
    const {
      response,
      error: transactionsError,
      run: loadTransactionTotalsRewardStatus,
    } = useOliveAPI<ITotalRewardsStatus[]>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(`${oliveBaseUrl}/totals_reward_status`, params),
      errorMessage: 'Error loading rewards status',
    })
    isLoadingRewards.value = true
    error.value = transactionsError.value

    await loadTransactionTotalsRewardStatus()

    if (response.value && !transactionsError.value.hasError)
      totalRewards.value = response.value
    else
      error.value = transactionsError.value

    isLoadingRewards.value = false
  }

  const loadTransactionTotalsBySupplierType = async (params?: ICardTransactionRequestParams) => {
    const {
      response,
      error: transactionsError,
      run: loadTransactionTotalsBySupplierType,
    } = useOliveAPI<ITransactionTotalsBySupplierType[]>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(`${oliveBaseUrl}/totals_supplier_type`, params),
      errorMessage: 'Error loading totals by supplier type',
    })
    isLoading.value = true
    error.value = transactionsError.value

    await loadTransactionTotalsBySupplierType()

    if (response.value && !transactionsError.value.hasError)
      totalBySupplierType.value = response.value
    else
      error.value = transactionsError.value

    isLoading.value = false
  }

  const loadTransactionTotalsByMember = async (params?: ICardTransactionRequestParams) => {
    const {
      response,
      error: transactionsError,
      run: loadTransactionTotalsByMember,
    } = useOliveAPI<IPlatformResponse<ITransactionTotalsByMember>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(`${oliveBaseUrl}/owed_to_member_amount_totals_member`, params),
      errorMessage: 'Error loading totals by member',
    })
    isLoading.value = true
    error.value = transactionsError.value

    await loadTransactionTotalsByMember()

    if (response.value && !transactionsError.value.hasError) {
      totalByMember.value = useArrayUnique([...response.value.items, ...totalByMember.value], (a, b) => a.memberId === b.memberId).value
      totalByMembersCurrentPage.value = [...response.value.items.map(m => m.memberId)]
      numberOfTotalsByMember.value = response.value.totalNumberOfRecords
    }
    else { error.value = transactionsError.value }

    isLoading.value = false
  }

  const loadTransactionTotalsOffer = async (params?: ICardTransactionRequestParams) => {
    const {
      response,
      error: transactionsError,
      run: loadTransactionTotalsOffer,
    } = useOliveAPI<ITotalRewardsStatus[]>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(`${oliveBaseUrl}/totals_reward_offer`, params),
      errorMessage: 'Error loading totals by offer',
    })
    isLoadingRewards.value = true
    error.value = transactionsError.value

    await loadTransactionTotalsOffer()

    if (response.value && !transactionsError.value.hasError)
      totalRewards.value = response.value
    else
      error.value = transactionsError.value

    isLoadingRewards.value = false
  }

  const postAuthorization = async (authorization: IAuthorization) => {
    const {
      error: transactionsError,
      run: postAuthorization,
    } = useOliveAPI<ICardTransaction>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/authorizations`,
      data: authorization,
      successMessage: 'Transaction Created',
      errorMessage: 'Error creating transaction',
    })
    isLoading.value = true
    error.value = getOliveError()

    await postAuthorization()

    if (transactionsError.value.hasError)
      error.value = transactionsError.value

    isLoading.value = false
  }

  const postSettlement = async (transactionId: string, settlement: ISettlement) => {
    const {
      error: transactionsError,
      run: postSettlement,
    } = useOliveAPI<ICardTransaction>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${transactionId}/settlement`,
      data: settlement,
      successMessage: 'Transaction Settled',
      errorMessage: 'Error settling transaction',
    })
    isLoading.value = true
    error.value = getOliveError()

    await postSettlement()

    if (transactionsError.value.hasError)
      error.value = transactionsError.value

    isLoading.value = false
  }

  const postReturn = async (transactionId: string, r: IReturn) => {
    const {
      error: transactionsError,
      run: postReturn,
    } = useOliveAPI<ICardTransaction>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${transactionId}/return`,
      data: r,
      successMessage: 'Return successful',
      errorMessage: 'Error posting return',
    })
    isLoading.value = true
    error.value = getOliveError()

    await postReturn()

    if (transactionsError.value.hasError)
      error.value = transactionsError.value

    isLoading.value = false
  }

  const updateRewardStatus = async (params: IRewardRequestParams) => {
    const {
      error: transactionsError,
      run: updateRewardStatus,
    } = useOliveAPI<ICardTransaction>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${params.transactionId}/reward`,
      data: {
        status: params.status,
        rejectionReason: params.rejectionReason,
        distributedToMemberAmount: params.distributedToMemberAmount,
      },
      errorMessage: 'Error updating offer redemption status',
    })
    isLoading.value = true
    error.value = getOliveError()

    await updateRewardStatus()

    if (transactionsError.value.hasError)
      error.value = transactionsError.value

    isLoading.value = false
  }

  const reprocess = async (transactionId: string) => {
    const {
      error: transactionsError,
      run: reProcess,
    } = useOliveAPI({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${transactionId}/reprocess`,
      successMessage: 'Transaction is being reprocessed. Please refresh in a few moments',
      errorMessage: 'Error reprocessing transaction',
    })
    isLoading.value = true
    error.value = getOliveError()

    await reProcess()

    if (transactionsError.value.hasError)
      error.value = transactionsError.value

    isLoading.value = false
  }

  const assignRewardExternalId = async (transactionId: string, rewardExternalId: string) => {
    const {
      error: transactionsError,
      run: assignRewardExternalId,
    } = useOliveAPI<ICardTransaction>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${transactionId}/reward/external_id`,
      data: rewardExternalId,
      successMessage: 'Successfully added reward external ID',
      errorMessage: 'Error adding reward external ID',
    })
    isLoading.value = true
    error.value = getOliveError()

    await assignRewardExternalId()

    if (transactionsError.value.hasError)
      error.value = transactionsError.value

    isLoading.value = false
  }

  const clearCurrentPage = () => {
    transactionIdsCurrentPage.value = []
  }

  const clearFilter = () => {
    filter.value = {}
  }

  const clearMemberTransactions = (memberId: string) => {
    memberTransactions.value = memberTransactions.value.filter(t => t.memberId !== memberId)
  }
  // #endregion

  return {
    clearMemberTransactions,
    transactions,
    totalRewards,
    totalBySupplierType,
    totalByMember,
    numberOfTransactions,
    numberOfTotalsByMember,
    getTransactionsCurrentPage,
    getTotalByMembersCurrentPage,
    getTotalByMembersTransactionsCurrentPage,
    getFormattedRewardStatus,
    isLoading,
    isLoadingRewards,
    error,
    filter,
    loadTransactions,
    loadTransaction,
    getTransactionById,
    loadTransactionTotalsRewardStatus,
    loadTransactionTotalsBySupplierType,
    loadTransactionTotalsByMember,
    loadTransactionTotalsOffer,
    postAuthorization,
    postSettlement,
    postReturn,
    updateRewardStatus,
    reprocess,
    assignRewardExternalId,
    clearCurrentPage,
    clearFilter,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useCardTransactionsStore as any, import.meta.hot))
