<script lang="ts" setup>
const clientsStore = useClientsStore()
const contextStore = useContextStore()
const userStore = useUserStore()

const clientsCount = ref(clientsStore.clients !== null ? clientsStore.clients.length : 0)
const corporatesCount = ref(userStore.me && userStore.me.corporates ? userStore.me.corporates.length : 0)

const showClientsList = clientsCount.value > 1 || (clientsCount.value > 0 && corporatesCount.value > 0)
const showCorporatesList = corporatesCount.value > 1 || (clientsCount.value > 0 && corporatesCount.value > 0)

const buttonWidth = ref(showClientsList || showCorporatesList ? 160 : 140)

const DEFAULT_LOGO_URL = '/logo.png'
const logoUrl = ref(DEFAULT_LOGO_URL)
const DEFAULT_LOGO_ALT = ''
const logoAlt = ref(DEFAULT_LOGO_ALT)

const clientSelectorMenu = ref<boolean>(false)
const clientSelectorDialog = ref<boolean>(false)

async function loadClientLogo() {
  const selectedClient = contextStore.selectedClient
  logoUrl.value = selectedClient?.logoUrl || DEFAULT_LOGO_URL
  logoAlt.value = selectedClient?.fullName || DEFAULT_LOGO_ALT
}
loadClientLogo()

async function clientCorporateSelection() {
  loadClientLogo()
  clientSelectorMenu.value = false
  clientSelectorDialog.value = false
}

onMounted(() => {
  window.addEventListener('resize', clientCorporateSelection, false)
})
</script>

<template>
  <v-sheet
    :width="buttonWidth"
    :class="!(showClientsList || showCorporatesList) ? 'ps-2' : ''"
  >
    <div v-if="showClientsList || showCorporatesList">
      <div class="d-none d-sm-flex">
        <v-menu
          v-model="clientSelectorMenu"
          location="bottom"
          :close-on-content-click="false"
          :close-on-back="true"
          :persistent="false"
        >
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              append-icon="mdi-chevron-down"
              size="large"
            >
              <img
                class="logoUrl"
                :src="logoUrl"
                :alt="logoAlt"
              >
            </v-btn>
          </template>
          <ClientSelectList
            :show-clients-list="showClientsList"
            :show-corporates-list="showCorporatesList"
            @on-selected="clientCorporateSelection"
          />
        </v-menu>
      </div>
      <div class="d-flex d-sm-none">
        <v-dialog
          v-model="clientSelectorDialog"
          fullscreen
          :close-on-content-click="false"
          :close-on-back="true"
          :persistent="false"
        >
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              append-icon="mdi-chevron-down"
              size="large"
            >
              <img
                class="logoUrl"
                :src="logoUrl"
                :alt="logoAlt"
              >
            </v-btn>
          </template>
          <ClientSelectList
            :show-clients-list="showClientsList"
            :show-corporates-list="showCorporatesList"
            @on-selected="clientCorporateSelection"
          />
        </v-dialog>
      </div>
    </div>
    <img
      v-else
      class="logoUrl"
      :src="logoUrl"
      :alt="logoAlt"
    >
  </v-sheet>
</template>

<style lang="scss" scoped>
  .logoUrl {
    max-height: 40px;
    width: 120px;
    aspect-ratio: 3;
    object-fit: contain;
  }
</style>
