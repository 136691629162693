import type { LocationQueryValue, RouteParams } from 'vue-router'

import { redirectToPageNotFound } from '~/router'
import { SUPPORTED_LOCALE } from '~/types/common.types'

function guidValidation(guidString: string) {
  const rule = guidRule().at(0)
  return rule ? rule(guidString ?? '') : true
}

function getJSONObject(jsonString: string) {
  try {
    const res = JSON.parse(jsonString)
    return res
  }
  catch (e) {
    return 'Error parsing JSON'
  }
}

function isValidJSONObject(jsonString: string) {
  try {
    if (!getJSONObject(jsonString).includes('Error parsing JSON'))
      return true
  }
  catch (e) {
    return false
  }
}

function toSplitCamelCase(e: string) {
  if (typeof e !== 'string')
    return

  return e
    .split('_')
    .map((word) => {
      if (word.toLowerCase() === 'id')
        return 'ID'
      if (word.toLowerCase() === 'sso')
        return 'SSO'
      if (word.toLowerCase() === 'oidc')
        return 'OIDC'

      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

// Helper method to map Olive Enum data contract to a well formatted string to be used for presentation
function oliveEnumToReadableStringMapper(input: string | string[]) {
  if (Array.isArray(input)) {
    return input.map((input) => {
      return toSplitCamelCase(input)
    })
  }
  else {
    return toSplitCamelCase(input)
  }
}

// Helper method to map a string to an Olive Enum representation
function oliveEnumMapper(input: string | undefined) {
  if (input)
    return input.toLowerCase().replace(/\s+/g, '_')
}

function findClosestSliderIndex(target: number | undefined, sliderSteps: number[]): number | undefined {
  if (target === undefined)
    return

  let closestIndex = 0
  let closestDifference = Math.abs(target - sliderSteps[0])

  for (let i = 1; i < sliderSteps.length; i++) {
    const currentDifference = Math.abs(target - sliderSteps[i])

    if (currentDifference < closestDifference) {
      closestIndex = i
      closestDifference = currentDifference
    }
  }

  return closestIndex
}

async function loadStoresOrBrandsByIds(brandIds?: (string | undefined)[], storeIds?: (string | undefined)[]) {
  const filteredBrandIds: string[] = (brandIds?.filter((id): id is string => id !== undefined)) || []
  const filteredStoreIds: string[] = (storeIds?.filter((id): id is string => id !== undefined)) || []

  await Promise.all([useBrandsStore().loadBrandsByIds(filteredBrandIds), useStoresStore().loadStoresByIds(filteredStoreIds)])
}

async function loadAuthorizationsOrSettlementsByIds(authorizationIds?: (string | undefined)[], settlementIds?: (string | undefined)[]) {
  const filteredAuthorizationIds: string[] = (authorizationIds?.filter((id): id is string => id !== undefined)) || []
  const filteredSettlementIds: string[] = (settlementIds?.filter((id): id is string => id !== undefined)) || []

  await Promise.all([usePaymentNetworkTransactionsStore().loadAuthorizationsByIds(filteredAuthorizationIds), usePaymentNetworkTransactionsStore().loadSettlementsByIds(filteredSettlementIds)])
}

function setFocusOnElementById(elementId: string) {
  const input = document.getElementById(elementId) as HTMLInputElement
  if (input) {
    setTimeout(() => {
      input.focus()
    }, 100)
  }
}

function isEmptyString(value: string | undefined | null) {
  return (value == null || value === undefined || (typeof value === 'string' && value.trim().length === 0))
}

// returns a value of a primitive data type or the first item of an array/object
// returns undefined otherwise
function getFirstValue(obj: any): any {
  if (obj === undefined || obj === null || (typeof obj !== 'object' && !Array.isArray(obj)))
    return obj

  const keys = Object.keys(obj)
  if (keys.length > 0)
    return obj[keys[0]]

  return undefined
}

function oliveFullTextSearchConverter(value: string | undefined) {
  if (!isEmptyString(value))
    return `"${value!.replace(/"([^"]+)"/g, (_, match) => `${match}`).replace(/"/g, '').trim()}${value!.includes('*') ? '' : '*'}"`
}

function validateGuid(value: string | undefined) {
  if (!isEmptyString(value) && !guidRules.test(value as string))
    redirectToPageNotFound()
  else if (value === undefined)
    return ref(undefined)
  else
    return ref(value.toLowerCase())
}

function routeParamsToLowerCase(obj: RouteParams, validateGUID: boolean): RouteParams {
  const result: RouteParams = {}

  for (const key in obj) {
    const value = obj[key]
    if (typeof value === 'string') {
      result[key] = value.toLowerCase()
      if (validateGUID && !guidRules.test(result[key] as string))
        redirectToPageNotFound()
    }
    else if (Array.isArray(value)) {
      result[key] = value.map((item) => {
        if (typeof item === 'string') {
          if (validateGUID && !guidRules.test(result[key] as string))
            redirectToPageNotFound()
          return item.toLowerCase()
        }
        else {
          return item
        }
      })
    }
    else { result[key] = value }
  }

  return result
}

function getNormalizedRouteParams(validateGUID = true): RouteParams {
  const routeParams = routeParamsToLowerCase(useRoute().params, validateGUID)
  return routeParams
}

function groupBy(arr: any[], property: string) {
  return arr.reduce((memo: any, x: any) => {
    if (!memo[x[property]])
      memo[x[property]] = []
    memo[x[property]].push(x)
    return memo
  }, {})
}

function getRandomIntInclusive(min: number, max: number) {
  const minCeiled = Math.ceil(min)
  const maxFloored = Math.floor(max)
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled)
}

// check equality of two objects based on an object's property
function areArraysOfObjectsEqual(arr1: any[], arr2: any[], property: string): boolean {
  if (arr1 && arr2) {
    if (arr1.length !== arr2.length)
      return false

    const sortedArr1 = arr1.slice().sort((a, b) => a[property] < b[property] ? -1 : 1)
    const sortedArr2 = arr2.slice().sort((a, b) => a[property] < b[property] ? -1 : 1)

    return sortedArr1.every(obj1 => sortedArr2.some(obj2 => obj1[property] === obj2[property]))
  }
  return false
}

function mergeArrays<T>(...arrays: T[][]): T[] {
  return arrays.reduce((mergedArray, array) => mergedArray.concat(array), [])
}

function serializeIterable<T>(iterable: Iterable<T>): { [key: string]: T } {
  const serializedData: { [key: string]: T } = {}
  for (const item of iterable) {
    const [key, value] = item as [string, T]
    serializedData[key] = value
  }
  return serializedData
}

function getDateTimeUTCFilename() {
  return new Date().toISOString().replaceAll(':', '.')
}

function isSuperset(a: string[], b: string[]): boolean {
  const setA = new Set(a)
  const setB = new Set(b)

  for (const item of setB) {
    if (!setA.has(item)) {
      return false
    }
  }
  return true
}

function getPageTitle(pageName?: string, clientCorporateName?: string) {
  const fixedPageName = 'Olive Customer Portal'
  return `${pageName ? `${pageName} - ` : ''}${clientCorporateName ? `${clientCorporateName} - ` : ''}${fixedPageName}`
}

function intlNumberFormatted(numberToBeFormatted: number) {
  return new Intl.NumberFormat(SUPPORTED_LOCALE.EN_US).format(numberToBeFormatted)
}

function locationQueryValueToNumber(value: LocationQueryValue): number | undefined {
  if (Array.isArray(value)) {
    return value.length > 0 ? Number(value[0]) || undefined : undefined
  }
  return value !== null ? Number(value) || undefined : undefined
}

// resets all properties in an object to undefined
function setAllObjectPropertiesToUndefined<T extends Record<string, any>>(obj: T): T {
  return Object.assign(obj, ...Object.keys(obj).map(key => ({ [key]: undefined })))
}

function hexToRgba(hex: string, alpha = 1) {
  hex = hex.replace(/^#/, '')

  if (hex.length === 3) {
    hex = hex.split('').map(char => char + char).join('')
  }

  const r = Number.parseInt(hex.substring(0, 2), 16)
  const g = Number.parseInt(hex.substring(2, 4), 16)
  const b = Number.parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export {
  areArraysOfObjectsEqual,
  findClosestSliderIndex,
  getDateTimeUTCFilename,
  getFirstValue,
  getJSONObject,
  getNormalizedRouteParams,
  getPageTitle,
  getRandomIntInclusive,
  groupBy,
  guidValidation,
  hexToRgba,
  intlNumberFormatted,
  isEmptyString,
  isSuperset,
  isValidJSONObject,
  loadAuthorizationsOrSettlementsByIds,
  loadStoresOrBrandsByIds,
  locationQueryValueToNumber,
  mergeArrays,
  oliveEnumMapper,
  oliveEnumToReadableStringMapper,
  oliveFullTextSearchConverter,
  serializeIterable,
  setAllObjectPropertiesToUndefined,
  setFocusOnElementById,
  toSplitCamelCase,
  validateGuid,
}
