import type { IOliveError } from '~/types/errors.types'
import type { IPaymentNetworkTransactionAuthorization, IPaymentNetworkTransactionAuthorizationRequestParams, IPaymentNetworkTransactionReturn, IPaymentNetworkTransactionReturnRequestParams, IPaymentNetworkTransactionSettlement, IPaymentNetworkTransactionSettlementRequestParams } from '~/types/paymentNetworkTransaction.types'
import type { IPlatformResponse } from '~/types/platform.types'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { HTTP_METHOD } from '~/types/common.types'
import { PAYMENT_NETWORK_TRANSACTION_TABS } from '~/types/paymentNetworkTransaction.types'

const BATCH_SIZE = 25

export const usePaymentNetworkTransactionsStore = defineStore('paymentNetworkTransactions', () => {
  const oliveBaseAuthorizationsUrl = '/authorizations'
  const oliveBaseSettlementsUrl = '/settlements'
  const oliveBaseReturnsUrl = '/returns'

  // #region state
  const authorizations = ref<IPaymentNetworkTransactionAuthorization[]>([])
  const authorizationIdsCurrentPage = ref<string[]>([])
  const numberOfAuthorizations = ref<number | undefined>()
  const authorizationError = ref<IOliveError>(getOliveError())
  const isLoadingAuthorizations = ref(false)
  const authorizationFilter = ref<IPaymentNetworkTransactionAuthorizationRequestParams>({})

  const settlements = ref<IPaymentNetworkTransactionSettlement[]>([])
  const settlementIdsCurrentPage = ref<string[]>([])
  const numberOfSettlements = ref<number | undefined>()
  const settlementError = ref<IOliveError>(getOliveError())
  const isLoadingSettlements = ref(false)
  const settlementFilter = ref<IPaymentNetworkTransactionSettlementRequestParams>({})

  const returns = ref<IPaymentNetworkTransactionReturn[]>([])
  const returnIdsCurrentPage = ref<string[]>([])
  const numberOfReturns = ref<number | undefined>()
  const returnError = ref<IOliveError>(getOliveError())
  const isLoadingReturns = ref(false)
  const returnFilter = ref<IPaymentNetworkTransactionReturnRequestParams>({})

  const commonFilter = ref<IPaymentNetworkTransactionAuthorizationRequestParams | IPaymentNetworkTransactionSettlementRequestParams | IPaymentNetworkTransactionReturnRequestParams>({})

  const latestAuthorizationRequestTime = ref()
  const latestSettlementRequestTime = ref()
  const latestReturnRequestTime = ref()
  // #endregion

  // #region getters
  const getAuthorizationById = computed(() => {
    return (id: string) => authorizations.value.find(a => a.id === id)
  })

  const getAuthorizationsByIds = computed(() => {
    return (ids: string[]) => authorizations.value.filter(a => ids.includes(a.id))
  })

  const getAuthorizationsCurrentPage = computed(() => {
    return authorizations.value.filter(a => authorizationIdsCurrentPage.value.includes(a.id))
  })
  const getSettlementsCurrentPage = computed(() => {
    return settlements.value.filter(a => settlementIdsCurrentPage.value.includes(a.id))
  })

  const getSettlementById = computed(() => {
    return (id: string) => settlements.value.find(s => s.id === id)
  })

  const getSettlementsByIds = computed(() => {
    return (ids: string[]) => settlements.value.filter(s => ids.includes(s.id))
  })

  const getReturnsCurrentPage = computed(() => {
    return returns.value.filter(a => returnIdsCurrentPage.value.includes(a.id))
  })

  // #region actions
  const loadAuthorizations = async (
    params?: IPaymentNetworkTransactionAuthorizationRequestParams,
  ) => {
    const {
      response,
      error: reqError,
      run: loadAuthorizations,
    } = useOliveAPI<IPlatformResponse<IPaymentNetworkTransactionAuthorization>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(`${oliveBaseAuthorizationsUrl}`, params),
      errorMessage: 'Error loading payment network transaction authorizations',
    })
    isLoadingAuthorizations.value = true
    authorizationError.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestAuthorizationRequestTime.value = currentRequestDateTime

    await loadAuthorizations()

    if (response.value && !reqError.value.hasError) {
      authorizations.value = useArrayUnique([...response.value.items, ...authorizations.value], (a, b) => a.id === b.id).value

      if (latestAuthorizationRequestTime.value === currentRequestDateTime) {
        authorizationIdsCurrentPage.value = [...response.value.items.map(a => a.id)]
        numberOfAuthorizations.value = response.value?.totalNumberOfRecords
      }
    }
    else { authorizationError.value = reqError.value }

    isLoadingAuthorizations.value = false
  }

  const loadAuthorizationsByIds = async (ids: string[]) => {
    const authorizationIdsFiltered = [...new Set(ids.filter(s => !getAuthorizationById.value(s)).map(t => t as string).filter(s => !(s === null || s === undefined)))]

    if (authorizationIdsFiltered.length === 0)
      return

    const batches = []
    do {
      batches.push(loadAuthorizations({ pageSize: authorizationIdsFiltered.slice(0, BATCH_SIZE).length, id: authorizationIdsFiltered.slice(0, BATCH_SIZE) }))
      authorizationIdsFiltered.splice(0, BATCH_SIZE)
    }
    while (authorizationIdsFiltered.length > 0)
    await Promise.all(batches)
  }

  const loadSettlements = async (
    params?: IPaymentNetworkTransactionSettlementRequestParams,
  ) => {
    const {
      response,
      error: reqError,
      run: loadSettlements,
    } = useOliveAPI<IPlatformResponse<IPaymentNetworkTransactionSettlement>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(`${oliveBaseSettlementsUrl}`, params),
      errorMessage: 'Error loading payment network transaction settlements',
    })
    isLoadingSettlements.value = true
    settlementError.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestSettlementRequestTime.value = currentRequestDateTime

    await loadSettlements()

    if (response.value && !reqError.value.hasError) {
      settlements.value = useArrayUnique([...response.value.items, ...settlements.value], (a, b) => a.id === b.id).value

      if (latestSettlementRequestTime.value === currentRequestDateTime) {
        settlementIdsCurrentPage.value = [...response.value.items.map(a => a.id)]
        numberOfSettlements.value = response.value?.totalNumberOfRecords
      }
    }
    else { settlementError.value = reqError.value }

    isLoadingSettlements.value = false
  }

  const loadSettlementsByIds = async (ids: string[]) => {
    const settlementIdsFiltered = [...new Set(ids.filter(s => !getSettlementById.value(s)).map(t => t as string).filter(s => !(s === null || s === undefined)))]

    if (settlementIdsFiltered.length === 0)
      return

    const batches = []
    do {
      batches.push(loadSettlements({ pageSize: settlementIdsFiltered.slice(0, BATCH_SIZE).length, id: settlementIdsFiltered.slice(0, BATCH_SIZE) }))
      settlementIdsFiltered.splice(0, BATCH_SIZE)
    }
    while (settlementIdsFiltered.length > 0)
    await Promise.all(batches)
  }

  const loadReturns = async (
    params?: IPaymentNetworkTransactionReturnRequestParams,
  ) => {
    const {
      response,
      error: reqError,
      run: loadReturns,
    } = useOliveAPI<IPlatformResponse<IPaymentNetworkTransactionReturn>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(`${oliveBaseReturnsUrl}`, params),
      errorMessage: 'Error loading payment network transaction returns',
    })
    isLoadingReturns.value = true
    returnError.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestReturnRequestTime.value = currentRequestDateTime

    await loadReturns()

    if (response.value && !reqError.value.hasError) {
      returns.value = useArrayUnique([...response.value.items, ...returns.value], (a, b) => a.id === b.id).value

      if (latestReturnRequestTime.value === currentRequestDateTime) {
        returnIdsCurrentPage.value = [...response.value.items.map(a => a.id)]
        numberOfReturns.value = response.value?.totalNumberOfRecords
      }
    }
    else { returnError.value = reqError.value }

    isLoadingReturns.value = false
  }

  const reprocessAuthorization = async (transactionId: string) => {
    const {
      error: transactionsError,
      run: reprocessAuthorization,
    } = useOliveAPI({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseAuthorizationsUrl}/${transactionId}/process`,
      successMessage: 'Authorization is being reprocessed. Please refresh in a few moments',
      errorMessage: 'Error reprocessing Authorization',
    })
    isLoadingAuthorizations.value = true
    authorizationError.value = getOliveError()

    await reprocessAuthorization()

    if (transactionsError.value.hasError)
      authorizationError.value = transactionsError.value

    isLoadingAuthorizations.value = false
  }

  const reprocessSettlement = async (transactionId: string) => {
    const {
      error: transactionsError,
      run: reprocessSettlement,
    } = useOliveAPI({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseSettlementsUrl}/${transactionId}/process`,
      successMessage: 'Settlement is being reprocessed. Please refresh in a few moments',
      errorMessage: 'Error reprocessing Settlement',
    })
    isLoadingSettlements.value = true
    settlementError.value = getOliveError()

    await reprocessSettlement()

    if (transactionsError.value.hasError)
      settlementError.value = transactionsError.value

    isLoadingSettlements.value = false
  }

  const reprocessReturn = async (transactionId: string) => {
    const {
      error: transactionsError,
      run: reprocessReturn,
    } = useOliveAPI({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseReturnsUrl}/${transactionId}/process`,
      successMessage: 'Return is being reprocessed. Please refresh in a few moments',
      errorMessage: 'Error reprocessing Return',
    })
    isLoadingReturns.value = true
    returnError.value = getOliveError()

    await reprocessReturn()

    if (transactionsError.value.hasError)
      returnError.value = transactionsError.value

    isLoadingReturns.value = false
  }

  const clearCurrentPage = () => {
    authorizationIdsCurrentPage.value = []
    settlementIdsCurrentPage.value = []
    returnIdsCurrentPage.value = []
  }

  const clearFilter = () => {
    authorizationFilter.value = {}
    settlementFilter.value = {}
    returnFilter.value = {}
  }

  const setFiltersCommonProperties = (requestParams: IPaymentNetworkTransactionAuthorizationRequestParams | IPaymentNetworkTransactionSettlementRequestParams | IPaymentNetworkTransactionReturnRequestParams, newTab: PAYMENT_NETWORK_TRANSACTION_TABS) => {
    const filteredParams: any = {}

    if (requestParams.paymentNetworkTransactionId !== undefined)
      filteredParams.paymentNetworkTransactionId = requestParams.paymentNetworkTransactionId

    if (requestParams.paymentNetworkCardExternalId !== undefined)
      filteredParams.paymentNetworkCardExternalId = requestParams.paymentNetworkCardExternalId

    if (requestParams.amount !== undefined)
      filteredParams.amount = requestParams.amount

    if (requestParams.billingAmount !== undefined) {
      if (newTab !== PAYMENT_NETWORK_TRANSACTION_TABS.RETURNS)
        filteredParams.billingAmount = requestParams.billingAmount
    }

    if (requestParams.currencyCode !== undefined)
      filteredParams.currencyCode = requestParams.currencyCode

    if (requestParams.billingCurrencyCode !== undefined) {
      if (newTab !== PAYMENT_NETWORK_TRANSACTION_TABS.RETURNS)
        filteredParams.billingCurrencyCode = requestParams.billingCurrencyCode
    }

    if (requestParams.visaStoreName !== undefined)
      filteredParams.visaStoreName = requestParams.visaStoreName

    if (requestParams.visaStoreId !== undefined)
      filteredParams.visaStoreId = requestParams.visaStoreId

    if (requestParams.visaMerchantId !== undefined)
      filteredParams.visaMerchantId = requestParams.visaMerchantId

    if (requestParams.visaMerchantName !== undefined) {
      if (newTab !== PAYMENT_NETWORK_TRANSACTION_TABS.AUTHORIZATIONS)
        filteredParams.visaMerchantName = requestParams.visaMerchantName
    }

    if (requestParams.mcMerchantId !== undefined)
      filteredParams.mcMerchantId = requestParams.mcMerchantId

    if (requestParams.mLocationId !== undefined)
      filteredParams.mcLocationId = requestParams.mcLocationId

    if (requestParams.mcBankRefNumber !== undefined) {
      if (newTab !== PAYMENT_NETWORK_TRANSACTION_TABS.AUTHORIZATIONS)
        filteredParams.mcBankRefNumber = requestParams.mcBankRefNumber
    }

    if (requestParams.mcMerchantDbaName !== undefined) {
      if (newTab !== PAYMENT_NETWORK_TRANSACTION_TABS.AUTHORIZATIONS)
        filteredParams.mcMerchantDbaName = requestParams.mcMerchantDbaName
    }

    if (requestParams.mcc !== undefined)
      filteredParams.mcc = requestParams.mcc

    if (requestParams.scheme !== undefined)
      filteredParams.scheme = requestParams.scheme

    if (requestParams.authCode !== undefined)
      filteredParams.authCode = requestParams.authCode

    if (requestParams.messageType !== undefined) {
      if (newTab !== PAYMENT_NETWORK_TRANSACTION_TABS.SETTLEMENTS)
        filteredParams.messageType = requestParams.messageType
    }

    commonFilter.value = filteredParams
  }

  return {
    authorizations,
    numberOfAuthorizations,
    authorizationError,
    isLoadingAuthorizations,
    getAuthorizationsCurrentPage,
    getAuthorizationById,
    getAuthorizationsByIds,
    loadAuthorizations,
    loadAuthorizationsByIds,
    reprocessAuthorization,
    authorizationFilter,

    settlements,
    numberOfSettlements,
    settlementError,
    isLoadingSettlements,
    getSettlementsCurrentPage,
    getSettlementById,
    getSettlementsByIds,
    loadSettlements,
    loadSettlementsByIds,
    reprocessSettlement,
    settlementFilter,

    returns,
    numberOfReturns,
    returnError,
    isLoadingReturns,
    getReturnsCurrentPage,
    loadReturns,
    reprocessReturn,
    returnFilter,

    clearCurrentPage,
    clearFilter,
    setFiltersCommonProperties,
    commonFilter,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePaymentNetworkTransactionsStore as any, import.meta.hot))
