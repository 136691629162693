import type { OliveRequestParams } from './common.types'
import type { IPaginationOptions } from './platform.types'

// #region enums
export enum WORK_ITEM_STATUS {
  CREATED = 'created',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export enum WORK_ITEM_TYPE {
  GENERIC = 'generic',
  NEW_PENDING_OFFER = 'new_pending_offer',
  MASTERCARD_MERCHANT_ONBOARDING_FAILURE = 'mastercard_merchant_onboarding_failure',
  UNMATCHED_LOYALIZE_TRANSACTION = 'unmatched_loyalize_transaction',
  MISMATCHED_TRIPLE_UP_REWARD_OFFER = 'mismatched_triple_up_reward_offer',
}

export enum WORK_ITEM_SOURCE {
  CREATE_WORK_ITEM_API = 'CreateWorkItemApi',
  CREATE_OFFER_API = 'CreateOfferApi',
}
// #endregion

// #region interfaces
export interface IWorkItem {
  id: string
  type: WORK_ITEM_TYPE
  status: WORK_ITEM_STATUS
  title: string
  description?: string
  notes?: string
  created: string
  creatorUserId?: string
  source: WORK_ITEM_SOURCE
  clientId?: string
  offerId?: string
  transactionId?: string
  loyalizeTransactionId?: string
  memberId?: string
  accessDevelopmentTransactionId?: string
}

export interface IWorkItemRequestParams extends OliveRequestParams, IPaginationOptions {
  id?: string
  type?: WORK_ITEM_TYPE[]
  status?: WORK_ITEM_STATUS[]
  created?: string[]
  offerId?: string
  clientId?: string
  search?: string
}

export interface IWorkItemsCount {
  count: number
  status: WORK_ITEM_STATUS
  type: WORK_ITEM_TYPE
}

export interface IWorkItemsCountsParams extends OliveRequestParams {
  clientId?: string | string[]
  status?: WORK_ITEM_STATUS | WORK_ITEM_STATUS[]
  type?: WORK_ITEM_TYPE | WORK_ITEM_TYPE[]
}
// #endregion
