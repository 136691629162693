import type { ICorporate, ICorporateRequestParams } from '~/types/corporate.types'
import type { IOliveError } from '~/types/errors.types'
import type { IPlatformResponse } from '~/types/platform.types'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { HTTP_METHOD } from '~/types/common.types'
import { IN_USE_BY_CORPORATE } from '~/types/corporate.types'

const BATCH_SIZE = 25

export const useCorporatesStore = defineStore('corporates', () => {
  const oliveBaseUrl = '/corporates'

  // #region state
  const corporates = ref<ICorporate[]>([])
  const filteredCorporates = ref<ICorporate[]>([])
  const selectedCorporateId = ref<string>()
  const corporateIdsCurrentPage = ref<string[]>([])
  const corporateIdsAll = ref<string[]>([])
  const numberOfCorporates = ref<number | undefined>()
  const numberOfPages = ref<number | undefined>()
  const error = ref<IOliveError>(getOliveError())
  const isLoading = ref(false)
  const filter = ref<ICorporateRequestParams>({})

  const latestRequestTime = ref()
  // #endregion

  // #region getters
  const getCorporateById = computed(() => {
    return (id: string) => corporates.value.find(c => c.id === id)
  })

  const getCorporatesByIds = computed(() => {
    return (ids: string[]) => corporates.value.filter(c => ids.includes(c.id))
  })

  const getCorporatesCurrentPage = computed (() => {
    return corporates.value.filter(c => corporateIdsCurrentPage.value.includes(c.id))
  })

  const getAllCorporates = computed (() => {
    return corporates.value.filter(c => corporateIdsAll.value.includes(c.id))
  })

  const getSelectedCorporate = computed(() => {
    if (selectedCorporateId.value)
      return getCorporateById.value(selectedCorporateId.value)
  })

  const getCorporateCreatedDate = computed(() => {
    return (id: string) => corporates.value.find(c => c.id === id)?.created
  })
  // #endregion

  // #region actions
  const loadCorporates = async (params?: ICorporateRequestParams, all = false) => {
    const {
      response,
      error: corporateError,
      run: loadCorporates,
    } = useOliveAPI<IPlatformResponse<ICorporate>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Error loading corporates',
    })
    isLoading.value = true
    error.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadCorporates()

    if (response.value?.items && !corporateError.value.hasError) {
      corporates.value = useArrayUnique([...response.value.items, ...corporates.value], (a, b) => a.id === b.id).value

      if (all) {
        corporateIdsAll.value = [...response.value.items.map(c => c.id)]
      }
      else {
        if (latestRequestTime.value === currentRequestDateTime) {
          corporateIdsCurrentPage.value = [...response.value.items.map(m => m.id)]
          numberOfCorporates.value = response.value.totalNumberOfRecords
        }
      }
    }
    else { error.value = corporateError.value }

    isLoading.value = false
  }

  const loadCorporate = async (corporateId: string) => {
    selectedCorporateId.value = corporateId

    if (getCorporateById.value(corporateId) || corporateId === IN_USE_BY_CORPORATE.ALL)
      return

    const {
      response,
      error: corporateError,
      run: loadCorporate,
    } = useOliveAPI<ICorporate>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${corporateId}`,
      errorMessage: 'Error loading corporates',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadCorporate()

    if (response.value && !corporateError.value.hasError)
      corporates.value = useArrayUnique([response.value, ...corporates.value], (a, b) => a.id === b.id).value
    else error.value = corporateError.value

    isLoading.value = false
  }

  const loadAllCorporates = async (params?: ICorporateRequestParams) => {
    filteredCorporates.value = []
    let pageNumber = 1
    const pageSize = 1000

    do {
      await loadCorporates({ ...params, pageNumber, pageSize }, true)
      filteredCorporates.value.push(...getAllCorporates.value)
      pageNumber++
    } while (pageNumber <= Number(numberOfPages.value))
  }

  const loadCorporateByIds = async (ids: string[]) => {
    const corporateIdsFiltered = [...new Set(ids.filter(c => !getCorporateById.value(c)).map(t => t as string).filter(s => !(s === null || s === undefined)))]

    if (corporateIdsFiltered.length === 0)
      return

    const batches = []
    do {
      batches.push(loadCorporates({ pageSize: corporateIdsFiltered.slice(0, BATCH_SIZE).length, corporateId: corporateIdsFiltered.slice(0, BATCH_SIZE) }))
      corporateIdsFiltered.splice(0, BATCH_SIZE)
    }
    while (corporateIdsFiltered.length > 0)
    await Promise.all(batches)
  }

  const addCorporate = async (corporate: ICorporate) => {
    const {
      response,
      error: corporateError,
      run: addCorporate,
    } = useOliveAPI<ICorporate>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}`,
      data: corporate,
      successMessage: 'Corporate created successfully',
      errorMessage: 'Failed to create corporate',
    })
    isLoading.value = true
    error.value = getOliveError()

    await addCorporate()

    if (response.value && !corporateError.value.hasError) {
      corporates.value = useArrayUnique([response.value, ...corporates.value], (a, b) => a.id === b.id).value
      selectedCorporateId.value = response.value.id
    }
    else { error.value = corporateError.value }

    isLoading.value = false
  }

  const updateCorporate = async (corporate: ICorporate) => {
    selectedCorporateId.value = corporate.id

    const {
      response,
      error: corporateError,
      run: updateCorporate,
    } = useOliveAPI<ICorporate>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${corporate.id}`,
      data: corporate,
      successMessage: 'Corporate updated successfully',
      errorMessage: 'Failed to update corporate',
    })
    isLoading.value = true
    error.value = getOliveError()

    await updateCorporate()

    if (response.value && !corporateError.value.hasError)
      corporates.value = useArrayUnique([response.value, ...corporates.value], (a, b) => a.id === b.id).value
    else error.value = corporateError.value

    isLoading.value = false
  }

  const markInvoicePaid = async (corporateId: string, year: string, month: string) => {
    const {
      error: corporateError,
      run: markInvoicePaid,
    } = useOliveAPI({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${corporateId}/invoices/${year}/${month}/paid`,
      successMessage: 'Invoice was paid in full successfully',
      errorMessage: 'Failed to pay invoice',
    })
    isLoading.value = true
    error.value = getOliveError()

    await markInvoicePaid()

    if (error.value.hasError)
      error.value = corporateError.value

    isLoading.value = false
  }

  const payInvoice = async (corporateId: string, year: string, month: string, paymentMethodId: string) => {
    const {
      error: corporateError,
      run: payInvoice,
    } = useOliveAPI({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${corporateId}/invoices/${year}/${month}/pay`,
      data: { paymentMethodId },
      successMessage: 'Invoice has been paid successfully',
      errorMessage: 'Failed to pay invoice',
    })
    isLoading.value = true
    error.value = getOliveError()

    await payInvoice()

    if (error.value.hasError)
      error.value = corporateError.value

    isLoading.value = false
  }
  // #endregion

  // #region Clear
  const clearCurrentPage = () => {
    corporateIdsCurrentPage.value = []
  }

  const clearFilter = () => {
    filter.value = {}
  }

  const clearSelectedCorporate = () => {
    selectedCorporateId.value = undefined
  }
  // #endregion

  return {
    corporates,
    filteredCorporates,
    loadCorporate,
    loadCorporates,
    loadAllCorporates,
    loadCorporateByIds,
    updateCorporate,
    addCorporate,
    markInvoicePaid,
    payInvoice,
    getCorporateById,
    getCorporatesByIds,
    getCorporateCreatedDate,
    getCorporatesCurrentPage,
    getSelectedCorporate,
    clearSelectedCorporate,
    isLoading,
    numberOfCorporates,
    filter,
    error,
    clearCurrentPage,
    clearFilter,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useCorporatesStore as any, import.meta.hot))
