import type { CARD_SCHEME } from '~/types/card.types'
import type { IAdminClient, IClient, IClientCreateRequestParams } from '~/types/client.types'
import type { IOliveError } from '~/types/errors.types'
import type { IPlatformResponse } from '~/types/platform.types'
import { parseISO } from 'date-fns'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ROLE_ID } from '~/types/client.types'
import { HTTP_METHOD } from '~/types/common.types'
import { CONTEXT } from '~/types/navigation.types'

export const useClientsStore = defineStore('clients', () => {
  const oliveBaseUrl = '/clients'

  // #region state
  const clients = useStorage('clients', [] as IClient[], sessionStorage)
  const adminClient = useStorage('adminClient', { id: 'admin', fullName: 'All Clients' } as IAdminClient, sessionStorage)
  const numberOfClients = ref<number>(0)
  const createdClient = ref<IClient>()

  const isLoading = ref(false)
  const error = ref<IOliveError>(getOliveError())
  // #endregion

  // #region getters
  const getClientById = computed(() => {
    return (id: string) => clients.value.find(c => c.id === id)
  })

  const getClientsByIds = computed(() => {
    return (ids: string[]) => clients.value.filter(c => ids.includes(c.id))
  })

  const getClientByFullName = computed(() => {
    return (fullName: string) => clients.value.find(c => c.fullName === fullName)
  })

  const getClientCreatedDate = computed(() => {
    return (id: string) => getClientById.value(id)?.created
  })

  const getClientsCreatedMinDate = computed(() => {
    return clients.value.map(c => parseISO(c.created)).reduce((minDate, currentDate) => (currentDate < minDate ? currentDate : minDate))
  })

  const getCardSchemesWithVaulting = computed(() => {
    return (id: string): CARD_SCHEME[] => {
      const cardSchemes = clients.value.find(c => c.id === id)?.cardSchemes

      return cardSchemes
        ? Object.entries(cardSchemes)
          .filter(([, details]) => details.vaultingEnabled)
          .map(([cardName]) => cardName)
          .sort() as CARD_SCHEME[]
        : []
    }
  })
  // #endregion

  // #region actions
  const canAccessClient = (clientId: string): boolean => {
    if (numberOfClients.value === 0)
      return false

    const accessibleClient = clients.value.find((client) => {
      return client.id === clientId
    })

    if (accessibleClient)
      return true

    return false
  }

  const loadClients = async (): Promise<void> => {
    if ((!clients.value || numberOfClients.value > 0))
      return

    const {
      response,
      error: clientsError,
      run: loadClients,
    } = useOliveAPI<IPlatformResponse<IClient>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, { pageSize: 1000 }),
      errorMessage: 'Error loading clients',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadClients()

    if (response.value?.items && response.value?.items.length > 0 && !clientsError.value.hasError) {
      const contextStore = useContextStore()

      clients.value = useArrayUnique([...response.value.items, ...clients.value], (a, b) => a.id === b.id).value.sort((a, b) => a.fullName.localeCompare(b.fullName))

      numberOfClients.value = response.value.totalNumberOfRecords

      if (contextStore.viewContext !== CONTEXT.CORPORATE) {
        if (useUserStore().canAccessAllClients)
          contextStore.setClientId(adminClient.value.id)
        else if (numberOfClients.value > 0)
          contextStore.setClientId(clients.value[0].id)
      }

      if (useContextStore().selectedClientId && useContextStore().selectedClientId !== adminClient.value.id) {
        const foundIdx = clients.value.findIndex(client => client.id === useContextStore().selectedClientId)
        const client = clients.value.splice(foundIdx, 1)
        clients.value.unshift(client[0])
      }
    }

    if (clientsError.value.hasError)
      error.value = clientsError.value

    isLoading.value = false
  }

  const updateClient = async (client: IClient, successMessage?: string, errorMessage?: string, signal?: AbortSignal) => {
    const {
      response,
      error: clientsError,
      run: updateClient,
    } = useOliveAPI<IClient>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${client.id}`,
      data: client,
      successMessage: successMessage || 'Client updated successfully',
      errorMessage: errorMessage || 'Failed to update client',
    }, signal)
    isLoading.value = true
    error.value = getOliveError()

    const convertedPhoneNumber = convertPhoneNumberToE164(client.phone ?? '', client.countryCode)
    if (convertedPhoneNumber) {
      if (client.phone !== convertedPhoneNumber)
        client.phone = convertedPhoneNumber
    }

    await updateClient()

    if (response.value && !clientsError.value.hasError) {
      clients.value = useSorted(useArrayUnique([response.value, ...clients.value], (a, b) => a.id === b.id), (a, b) => {
        if (a.fullName.toLocaleLowerCase() < b.fullName.toLocaleLowerCase())
          return -1
        if (a.fullName.toLocaleLowerCase() > b.fullName.toLocaleLowerCase())
          return 1
        return 0
      }).value
    }
    else { error.value = clientsError.value }

    isLoading.value = false
  }

  const addClient = async (client: IClientCreateRequestParams, successMessage?: string, errorMessage?: string, signal?: AbortSignal) => {
    const {
      response,
      error: clientsError,
      run: addClient,
    } = useOliveAPI<IClient>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}`,
      data: { ...client, roleId: ROLE_ID } as IClientCreateRequestParams,
      successMessage: successMessage || 'Client created successfully',
      errorMessage: errorMessage || 'Failed to create client',
    }, signal)
    isLoading.value = true
    error.value = getOliveError()

    const convertedPhoneNumber = convertPhoneNumberToE164(client.phone ?? '', client.countryCode)
    if (convertedPhoneNumber) {
      if (client.phone !== convertedPhoneNumber)
        client.phone = convertedPhoneNumber
    }

    await addClient()

    if (response.value && !clientsError.value.hasError) {
      createdClient.value = response.value
      clients.value = useSorted(useArrayUnique([response.value, ...clients.value], (a, b) => a.id === b.id), (a, b) => {
        if (a.fullName.toLocaleLowerCase() < b.fullName.toLocaleLowerCase())
          return -1
        if (a.fullName.toLocaleLowerCase() > b.fullName.toLocaleLowerCase())
          return 1
        return 0
      }).value
    }
    else { error.value = clientsError.value }

    isLoading.value = false
  }
  // #endregion

  return {
    clients,
    adminClient,
    getClientById,
    getClientsByIds,
    getClientByFullName,
    getCardSchemesWithVaulting,
    getClientCreatedDate,
    getClientsCreatedMinDate,
    error,
    loadClients,
    updateClient,
    canAccessClient,
    addClient,
    isLoading,
    createdClient,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useClientsStore as any, import.meta.hot))
