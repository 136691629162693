import type { AUTHORIZATION_STATUS } from './authorization.types'
import type { CARD_SCHEME } from './card.types'
import type { OliveRequestParams } from './common.types'
import type { IPaginationOptions } from './platform.types'
import type { RETURN_STATUS } from './return.types'
import type { SETTLEMENT_STATUS } from './settlement.types'

// #region enums
export enum PAYMENT_NETWORK_TRANSACTION_TABS {
  AUTHORIZATIONS = 'authorizations',
  SETTLEMENTS = 'settlements',
  RETURNS = 'returns',
}
// #endregion

// #region interfaces
export interface IPaymentNetworkTransactionAuthorization {
  id: string
  purchaseDateTime: string
  scheme: CARD_SCHEME
  paymentNeworkTransactionId: string
  paymentNetworkCardExternalId: string
  amount: number
  currencyCode: string
  billingAmount: number
  billingCurrencyCode: string
  merchantId: string
  merchantName: string
  storeId: string
  mcc: string
  status: AUTHORIZATION_STATUS
  authCode: string
  messageType: string

  // Visa Auths
  visaStoreName?: string
  visaStoreId?: string
  visaMerchantId?: string

  // MC Auths
  merchNameLoc?: string
  mcLocationId?: string
  mcMerchantId?: string
}

export interface IPaymentNetworkTransactionAuthorizationRequestParams extends OliveRequestParams, IPaginationOptions {
  id?: string | string[]
  paymentNetworkTransactionId?: string[]
  paymentNetworkCardExternalId?: string[]
  amount?: string | string[]
  billingAmount?: string | string[]
  currencyCode?: string | string[]
  billingCurrencyCode?: string | string[]
  visaStoreName?: string | string[]
  merchNameLoc?: string | string[]
  visaMerchantId?: string | string[]
  mCMerchantId?: string | string[]
  visaStoreId?: string | string[]
  mCLocationId?: string | string[]
  mcc?: string[]
  purchaseDateTime?: string | string[]
  status?: AUTHORIZATION_STATUS | AUTHORIZATION_STATUS[]
  scheme?: string | string[]
  authCode?: string[]
  messageType?: string[]
}

export interface IPaymentNetworkTransactionSettlement {
  id: string
  purchaseDateTime: string
  scheme: CARD_SCHEME
  paymentNeworkTransactionId: string
  paymentNetworkCardExternalId: string
  amount: number
  currencyCode: string
  billingAmount: number
  billingCurrencyCode: string
  merchantId: string
  merchantName: string
  storeId: string
  mcc: string
  status: string
  authCode: string

  // Visa Settlements
  visaStoreName?: string
  visaStoreId?: string
  visaMerchantName?: string
  visaMerchantId?: string
  visaPromoCode?: string

  // MC Settlements
  mcMerchantId?: string
  mcLocationId?: string
  mcBankRefNumber?: string
  mcMerchantDbaName?: string
}

export interface IPaymentNetworkTransactionSettlementRequestParams extends OliveRequestParams, IPaginationOptions {
  id?: string | string[]
  paymentNetworkTransactionId?: string[]
  paymentNetworkCardExternalId?: string[]
  amount?: string | string[]
  billingAmount?: string | string[]
  currencyCode?: string | string[]
  billingCurrencyCode?: string | string[]
  visaStoreName?: string | string[]
  visaStoreId?: string | string[]
  visaMerchantName?: string | string[]
  mcMerchantDbaName?: string | string[]
  mCMerchantId?: string | string[]
  mCMLocationId?: string | string[]
  mCBankRefNumber?: string | string[]
  mcc?: string[]
  transactionDateTime?: string | string[]
  status?: SETTLEMENT_STATUS | SETTLEMENT_STATUS[]
  scheme?: string | string[]
  authCode?: string[]
}

export interface IPaymentNetworkTransactionReturn {
  id: string
  returnDateTime: string
  created: string
  scheme: CARD_SCHEME
  paymentNeworkTransactionId: string
  paymentNetworkCardExternalId: string
  amount: number
  currencyCode: string
  issuingSettlementAmount: number
  issuingSettlementCurrencyCode: string
  visaStoreName?: string
  visaStoreId?: string
  visaMerchantName?: string
  visaMerchantId?: string
  visaSettlementId?: string
  mCMerchantId?: string
  mCMLocationId?: string
  mCBankRefNumber?: string
  mcMerchantDbaName?: string
  mcc: string
  status: RETURN_STATUS
  authCode: string
  messageType: string
}

export interface IPaymentNetworkTransactionReturnRequestParams extends OliveRequestParams, IPaginationOptions {
  id?: string | string[]
  paymentNetworkTransactionId?: string[]
  paymentNetworkCardExternalId?: string[]
  amount?: string | string[]
  issuingSettlementAmount?: string | string[]
  currencyCode?: string | string[]
  issuingSettlementCurrencyCode?: string | string[]
  visaStoreName?: string | string[]
  visaStoreId?: string | string[]
  visaMerchantName?: string | string[]
  visaMerchantId?: string | string[]
  visaSettlementId?: string | string[]
  mcMerchantDbaName?: string | string[]
  mCMerchantId?: string | string[]
  mCMLocationId?: string | string[]
  mCBankRefNumber?: string | string[]
  mCMerchantDbaName?: string | string[]
  mcc?: string[]
  created?: string | string[]
  returnDateTime?: string | string[]
  status?: RETURN_STATUS | RETURN_STATUS[]
  scheme?: CARD_SCHEME | CARD_SCHEME[]
  authCode?: string[]
  messageType?: string[]
}
// #endregion
