import type { IOliveError, IVuetifyEmptyState } from '~/types/errors.types'
import { OLIVE_ERROR_CODE } from '~/types/errors.types'

function getErrorText(code: OLIVE_ERROR_CODE | undefined): IVuetifyEmptyState {
  switch (code) {
    case OLIVE_ERROR_CODE.AddExternalIdentifiersFromAuth_NoNewIdentifiers:
      return { title: 'Error', text: 'No new store external identifiers' }
    case OLIVE_ERROR_CODE.Incorrect_Mime_Type:
      return { title: 'Failed to download.', text: 'Incorrect MIME type detected.' }
  }
  return { title: 'Error' }
}

function getOliveError(hasError = false, code?: OLIVE_ERROR_CODE, message?: IVuetifyEmptyState): IOliveError {
  return { hasError, code, message: message || getErrorText(code) }
}

function shouldShowErrorNotification(handledErrors: OLIVE_ERROR_CODE[], errorCode: number): boolean {
  return !handledErrors.includes(errorCode)
}

export {
  getErrorText,
  getOliveError,
  shouldShowErrorNotification,
}
