import type { IOliveAPI } from '~/types/common.types'
import type { IOliveError, OLIVE_ERROR_CODE } from '~/types/errors.types'

/**
 * Helper method to make HTTP requests to Olive Platform
 * @param api IOliveAPI<K>
 * @param signal AbortSignal used to cancel the request
 * @event run()
 */
export function useOliveAPI<T, K = T | null>(api: IOliveAPI<K>, signal?: AbortSignal, handledErrorNotifications?: OLIVE_ERROR_CODE[]) {
  const response = ref<K | null>(api.defaultResponse ?? null)
  const error = ref<IOliveError>(getOliveError())

  const run = async () => {
    error.value = getOliveError()
    try {
      response.value = await platformApi.run(
        api.method,
        unref(api.url),
        api.data,
        { ...api.requestConfig, signal },
      )

      if (api.successMessage)
        useNotifications().notifySuccess(api.successMessage)
    }
    catch (e: any) {
      if (e.name === 'AbortError')
        return

      error.value = getOliveError(true, e.errorCode, {
        title: e.title || 'Error',
        text: e.errorCode === -1 ? api.errorMessage : e.message || api.errorMessage,
      })

      if (error.value && shouldShowErrorNotification(handledErrorNotifications ?? [], e.errorCode))
        useNotifications().notifyError(error.value.message.title, error.value.message.text)
    }
  }

  return {
    error,
    response,
    run,
  }
}
