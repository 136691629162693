<script setup lang="ts">
import type { INavMenu } from '~/types/navigation.types'
import { CLIENT_ROLE } from '~/types/client.types'
import { IN_USE_BY_CORPORATE } from '~/types/corporate.types'
import { CONTEXT } from '~/types/navigation.types'
import { OFFER_AVAILABILITY } from '~/types/offer.types'
import { PERMISSION } from '~/types/permission.types'
import { WORK_ITEM_STATUS } from '~/types/workItem.types'

const route = useRoute()
const router = useRouter()
const contextStore = useContextStore()
const usersStore = useUserStore()
const isAdmin = usersStore.canAccessAllClients
const selectedClientId = ref(contextStore.selectedClientId)
const selectedCorporateId = ref(contextStore.selectedCorporateId)

const contextNone = ref(contextStore.viewContext === CONTEXT.NONE)
const contextClient = ref(contextStore.viewContext === CONTEXT.CLIENT)
const contextCorporate = ref(contextStore.viewContext === CONTEXT.CORPORATE)

const workItemsStore = useWorkItemsStore()

const page = ref(router.currentRoute.value.name)
const navItem = ref(router.currentRoute.value.meta.navItem)

const isRoundingEnabled = computed(() => {
  return contextStore.selectedClient?.roundingEnabled
})

const dashboards = computed<INavMenu[]>(() => [
  {
    name: 'client-overview',
    title: 'Overview',
    icon: 'mdi-view-dashboard',
    value: 'client-overview',
    context: CONTEXT.CLIENT,
  },
  {
    name: 'corporate-overview',
    title: 'Overview',
    icon: 'mdi-view-dashboard',
    value: 'corporate-overview',
    context: CONTEXT.CORPORATE,
  },
  {
    name: 'overview',
    title: 'Overview',
    icon: 'mdi-view-dashboard',
    value: 'overview',
    context: CONTEXT.NONE,
  },
  {
    name: 'clients',
    title: 'Clients',
    icon: 'mdi-view-list-outline',
    value: 'clients',
    context: CONTEXT.NONE,
  },
  {
    name: 'offer-revenue',
    title: 'Offer Revenue Report',
    icon: 'mdi-cash-multiple',
    value: 'offer-revenue',
    context: CONTEXT.NONE,
  },
  {
    name: 'client-offer-revenue',
    title: 'Offer Revenue Report',
    icon: 'mdi-cash-multiple',
    value: 'client-offer-revenue',
    context: CONTEXT.CLIENT,
  },
  {
    name: 'work-items',
    title: 'Work Items',
    icon: 'mdi-briefcase',
    value: 'work-items',
    context: CONTEXT.NONE,
  },
])

const programSetup = computed<INavMenu[]>(() => {
  return [
    {
      name: 'client-rounding',
      title: 'Rounding',
      icon: 'mdi-arrow-up-circle',
      value: 'client-rounding',
      context: CONTEXT.CLIENT,
      hasAccess: isRoundingEnabled.value,
    },
    {
      name: 'client-loyalty',
      title: 'Loyalty',
      icon: 'mdi-seal',
      value: 'client-loyalty',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'client-members',
      title: 'Members',
      icon: 'mdi-account-credit-card',
      value: 'client-members',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'members',
      title: 'Members',
      icon: 'mdi-account-credit-card',
      value: 'members',
      context: CONTEXT.NONE,
    },
    {
      name: 'client-cards',
      title: 'Cards',
      icon: 'mdi-credit-card',
      value: 'client-cards',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'client-transactions',
      title: 'Transactions',
      icon: 'mdi-view-list',
      value: 'client-transactions',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'client-card-transactions',
      title: 'Client Card Transactions',
      icon: 'mdi-credit-card-plus',
      value: 'client-card-transactions',
      context: CONTEXT.NONE,
    },
    {
      name: 'client-client-card-transactions',
      title: 'Client Card Transactions',
      icon: 'mdi-credit-card-plus',
      value: 'client-client-card-transactions',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'transactions',
      title: 'Transactions',
      icon: 'mdi-view-list',
      value: 'transactions',
      context: CONTEXT.NONE,
      hasAccess: usersStore.checkUserPermission(PERMISSION.CAN_VIEW_SYSTEM),
    },
  ]
})
const merchantSetup = computed<INavMenu[]>(() => {
  return [
    {
      name: 'offers',
      title: 'Offers',
      icon: 'mdi-offer',
      value: 'offers',
      context: CONTEXT.NONE,
    },
    {
      name: 'corporates',
      title: 'Corporates',
      icon: 'mdi-domain',
      value: 'corporates',
      context: CONTEXT.NONE,
    },
    {
      name: 'brands',
      title: 'Brands',
      icon: 'mdi-tag',
      value: 'brands',
      context: CONTEXT.NONE,
    },
    {
      name: 'stores',
      title: 'Stores',
      icon: 'mdi-store',
      value: 'stores',
      context: CONTEXT.NONE,
    },
    {
      name: 'offer-redemptions',
      title: 'Offer Redemptions',
      icon: 'mdi-repeat',
      value: 'offer-redemptions',
      context: CONTEXT.NONE,
    },
    {
      name: 'accounts',
      title: 'Accounts',
      icon: 'mdi-store',
      value: 'accounts',
      context: CONTEXT.NONE,
    },
    {
      name: 'client-offers',
      title: 'Offers',
      icon: 'mdi-offer',
      value: 'client-offers',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'client-corporates',
      title: 'Corporates',
      icon: 'mdi-domain',
      value: 'client-corporates',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'client-brands',
      title: 'Brands',
      icon: 'mdi-tag',
      value: 'client-brands',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'client-stores',
      title: 'Stores',
      icon: 'mdi-store',
      value: 'client-stores',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'client-offer-redemptions',
      title: 'Offer Redemptions',
      icon: 'mdi-repeat',
      value: 'client-offer-redemptions',
      context: CONTEXT.CLIENT,
      listGroup: 'client-offer-redemptions',
    },
    {
      name: 'client-offer-redemptions',
      title: 'By Status',
      value: 'client-offer-redemptions',
      context: CONTEXT.CLIENT,
      listGroup: 'client-offer-redemptions',
      listGroupItem: true,
    },
    {
      name: 'client-offer-redemptions-members',
      title: 'By Member',
      value: 'client-offer-redemptions-members',
      context: CONTEXT.CLIENT,
      listGroup: 'client-offer-redemptions',
      listGroupItem: true,
    },
    {
      name: 'corporate-offers',
      title: 'Offers',
      icon: 'mdi-offer',
      value: 'corporate-offers',
      context: CONTEXT.CORPORATE,
    },
    {
      name: 'corporate-brands',
      title: 'Brands',
      icon: 'mdi-tag',
      value: 'corporate-brands',
      context: CONTEXT.CORPORATE,
    },
    {
      name: 'corporate-stores',
      title: 'Stores',
      icon: 'mdi-store',
      value: 'corporate-stores',
      context: CONTEXT.CORPORATE,
    },
    {
      name: 'corporate-offer-redemptions',
      title: 'Offer Redemptions',
      icon: 'mdi-repeat',
      value: 'corporate-offer-redemptions',
      context: CONTEXT.CORPORATE,
    },
    {
      name: 'corporate-account-details',
      title: 'Account',
      icon: 'mdi-account-cash',
      value: 'corporate-account-details',
      context: CONTEXT.CORPORATE,
    },
    {
      name: 'corporate-invoices',
      title: 'Invoices',
      icon: 'mdi-receipt-text',
      value: 'corporate-invoices',
      context: CONTEXT.CORPORATE,
    },
    {
      name: 'client-users-corporates',
      title: 'Corporate Users',
      icon: 'mdi-account-group',
      value: 'client-users-corporates',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'corporate-users',
      title: 'Corporate Users',
      icon: 'mdi-account-group',
      value: 'corporate-users',
      context: CONTEXT.CORPORATE,
    },
  ]
})
const settingsItems = computed<INavMenu[]>(() => {
  return [
    {
      name: 'client-api',
      title: 'API',
      icon: 'mdi-key',
      value: 'client-api',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'client-users-clients',
      title: 'Client Users',
      icon: 'mdi-account-supervisor',
      value: 'client-users-clients',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'client-card-enrollment-form',
      title: 'Card Enrollment Form',
      icon: 'mdi-credit-card-plus',
      value: 'client-card-enrollment-form',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'webhooks',
      title: 'Webhooks',
      icon: 'mdi-webhook',
      value: 'webhooks',
      context: CONTEXT.NONE,
    },
    {
      name: 'client-webhooks',
      title: 'Webhooks',
      icon: 'mdi-webhook',
      value: 'client-webhooks',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'users',
      title: 'Users',
      icon: 'mdi-account-supervisor',
      value: 'users',
      context: CONTEXT.NONE,
    },
    {
      name: 'documentation',
      title: 'API Documentation',
      icon: 'mdi-file-document',
      value: 'documentation',
      context: CONTEXT.NONE,
      hasAccess: usersStore.checkUserPermission(PERMISSION.CAN_VIEW_DOCUMENTATION),
    },
    {
      name: 'client-account-details',
      title: 'Client Account',
      icon: 'mdi-account-cash',
      value: 'client-account-details',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'client-payment-gateways',
      title: 'Payment Gateways',
      icon: 'mdi-bank-transfer',
      value: 'client-payment-gateways',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'client-mcc',
      title: 'Merchant Category Codes',
      icon: 'mdi-storefront',
      value: 'client-mcc',
      context: CONTEXT.CLIENT,
    },
    ...(isAdmin)
      ? [
          {
            name: 'client-merchant-onboarding-wizard',
            title: 'Merchant Onboarding Wizard',
            icon: 'mdi-store-cog',
            value: 'client-merchant-onboarding-wizard',
            context: CONTEXT.CLIENT,
          },
        ]
      : [],
    ...(isAdmin)
      ? [{
          name: 'corporate-merchant-onboarding-wizard',
          title: 'Merchant Onboarding Wizard',
          icon: 'mdi-store-cog',
          value: 'corporate-merchant-onboarding-wizard',
          context: CONTEXT.CORPORATE,
        }]
      : [],
    {
      name: 'client-settings',
      title: 'Client Settings',
      icon: 'mdi-cog',
      value: 'client-settings',
      context: CONTEXT.CLIENT,
    },
    {
      name: 'corporate-settings',
      title: 'Corporate Settings',
      icon: 'mdi-cog',
      value: 'corporate-settings',
      context: CONTEXT.CORPORATE,
    },
  ]
})
const admin = computed<INavMenu[]>(() => {
  return [
    {
      name: 'payment-network-cards',
      title: 'Payment Network Cards',
      icon: 'mdi-credit-card-multiple-outline',
      value: 'payment-network-cards',
      context: CONTEXT.NONE,
      hasAccess: isAdmin,
    },
    {
      name: 'payment-network-transactions',
      title: 'Payment Network Transactions',
      icon: 'mdi-database',
      value: 'payment-network-transactions',
      context: CONTEXT.NONE,
      hasAccess: isAdmin,
    },
    {
      name: 'payment-network-transaction-feed',
      title: 'Payment Network Feeds',
      icon: 'mdi-invoice-arrow-right-outline',
      value: 'payment-network-transaction-feed',
      context: CONTEXT.NONE,
      hasAccess: isAdmin,
    },
    {
      name: 'payment-method-transactions',
      title: 'Payment Method Transactions',
      icon: 'mdi-bank-transfer',
      value: 'payment-method-transactions',
      context: CONTEXT.NONE,
      hasAccess: isAdmin,
    },
    {
      name: 'ecm-usage',
      title: 'ECM Usage',
      icon: 'mdi-cards-outline',
      value: 'ecm-usage',
      context: CONTEXT.NONE,
      hasAccess: isAdmin,
    },
    {
      name: 'merchant-search',
      title: 'Merchant Search',
      icon: 'mdi-store-marker',
      value: 'merchant-search',
      context: CONTEXT.NONE,
      hasAccess: isAdmin,
    },
    {
      name: 'mcc',
      title: 'Merchant Category Codes',
      icon: 'mdi-storefront',
      value: 'mcc',
      context: CONTEXT.NONE,
    },
    {
      name: 'scheduled-jobs',
      title: 'Scheduled Jobs',
      icon: 'mdi-wrench-clock',
      value: 'scheduled-jobs',
      context: CONTEXT.NONE,
    },
  ]
})

function showDivider(menu: INavMenu[]): boolean | undefined {
  const adminMenu = menu.find(menuItem => menuItem.context === CONTEXT.NONE)
  const clientMenu = menu.find(menuItem => menuItem.context === CONTEXT.CLIENT)
  const corporateMenu = menu.find(menuItem => menuItem.context === CONTEXT.CORPORATE)
  return (adminMenu && contextNone.value) || (clientMenu && contextClient.value) || (corporateMenu && contextCorporate.value)
}

const showclientSettingsDivider = ref(showDivider(settingsItems.value))
const showAdminDivider = ref(showDivider(admin.value) && isAdmin)
const showMerchantSetupDivider = ref(showDivider(merchantSetup.value))
const showProgramSetupDivider = ref(showDivider(programSetup.value))

async function calcContextAndDividers() {
  contextNone.value = contextStore.viewContext === CONTEXT.NONE
  contextClient.value = contextStore.viewContext === CONTEXT.CLIENT
  contextCorporate.value = contextStore.viewContext === CONTEXT.CORPORATE

  showclientSettingsDivider.value = showDivider(settingsItems.value)
  showAdminDivider.value = showDivider(admin.value) && isAdmin
  showMerchantSetupDivider.value = showDivider(merchantSetup.value)
  showProgramSetupDivider.value = showDivider(programSetup.value)
}

function updateWorkItemsBadge() {
  const workItemsNavItem = dashboards.value.find(f => f.name === 'work-items')
  if (workItemsNavItem)
    workItemsNavItem.badgeCount = workItemsStore.newWorkItemsCount
}

async function callWorkItemsCounts() {
  if (contextStore.viewContext === CONTEXT.NONE && workItemsStore.newWorkItemsCount === undefined) {
    await workItemsStore.loadWorkItemsCounts({ status: WORK_ITEM_STATUS.CREATED })
    updateWorkItemsBadge()
  }
}

watch(() => contextStore.viewContext, async () => {
  await callWorkItemsCounts()
})
watch(() => contextStore.selectedClientId, (clientId) => {
  selectedClientId.value = clientId
  selectedCorporateId.value = contextStore.selectedCorporateId
  calcContextAndDividers()
})
watch(() => contextStore.selectedCorporateId, (corporateId) => {
  selectedClientId.value = contextStore.selectedClientId
  selectedCorporateId.value = corporateId
  calcContextAndDividers()
})
watch(router.currentRoute, (route) => {
  page.value = route.name
  navItem.value = route.meta.navItem
})

const getActiveItems = computed(() => {
  return (menu: INavMenu[]) => {
    if (contextNone.value)
      return menu.filter(f => f.context === CONTEXT.NONE && f.hasAccess !== false)
    else if (contextClient.value)
      return menu.filter(f => f.context === CONTEXT.CLIENT && f.hasAccess !== false)
    else if (contextCorporate.value)
      return menu.filter(f => f.context === CONTEXT.CORPORATE && f.hasAccess !== false)
  }
})

const getSubGroupItems = computed(() => {
  return (menu: INavMenu[], group: string) => {
    return getActiveItems.value(menu)?.filter(f => f.listGroup === group && f.listGroupItem === true)
  }
})

const appendQueryParams = computed(() => {
  return (pageName: string) => {
    if (contextClient.value && (pageName === 'client-brands' || pageName === 'client-stores'))
      return { corporateId: IN_USE_BY_CORPORATE.ALL }
    if (contextCorporate.value && (pageName === 'corporate-brands' || pageName === 'corporate-stores'))
      return { corporateId: selectedCorporateId.value }
    if (contextClient.value && (pageName === 'client-offers')) {
      const isAdminClient = contextStore.selectedClient?.role === CLIENT_ROLE.ADMIN
      return { availability: isAdminClient ? OFFER_AVAILABILITY.GLOBAL : OFFER_AVAILABILITY.CLIENT_ONLY }
    }
  }
})

watch(() => workItemsStore.newWorkItemsCount, () => {
  updateWorkItemsBadge()
})

onMounted(async () => {
  await callWorkItemsCounts()
})
</script>

<template>
  <v-list
    nav
    density="compact"
  >
    <template v-for="nav in getActiveItems(dashboards)">
      <v-list-item
        v-if="!nav.listGroup"
        :key="nav.value"
        :class="nav.name === route.name ? 'readonly-list-item' : ''"
        :to="{
          name: nav.name as string,
          ...(nav.context === CONTEXT.CLIENT && {
            params: { client: selectedClientId },
            query: appendQueryParams(nav.name),
          })
            || (nav.context === CONTEXT.CORPORATE && {
              params: { corporate: selectedCorporateId },
              query: appendQueryParams(nav.name) }),
        }"
        :title="nav.title"
        :value="`${nav.value}-${getRandomIntInclusive(1, 9999)}`"
        :active="navItem === nav.name"
        :disabled="nav.disabled"
        color="huntergreen"
      >
        <template #prepend>
          <v-icon
            size="small"
            :icon="nav.icon"
            class="mr-n4"
          />
        </template>
        <template #append>
          <v-badge
            v-if="nav.badgeCount"
            color="silver"
            :content="nav.badgeCount"
            inline
          />
        </template>
      </v-list-item>
      <v-list-group
        v-else-if="nav.listGroup && !nav.listGroupItem"
        :key="nav.listGroup"
      >
        <template #activator="{ props }">
          <v-list-item
            v-bind="props"
            :title="nav.title"
            :value="`${nav.value}-${getRandomIntInclusive(1, 1000)}`"
            :active="navItem === nav.name"
            :disabled="nav.disabled"
            color="huntergreen"
          >
            <template #prepend>
              <v-icon
                size="small"
                :icon="nav.icon"
                class="mr-n4"
              />
            </template>
          </v-list-item>
        </template>

        <template
          v-for="item in getSubGroupItems(dashboards, nav.listGroup)"
          :key="item.value"
        >
          <v-list-item
            :class="navItem === item.name ? 'readonly-list-item' : ''"
            :to="{
              name: item.name as string,
              ...(item.context === CONTEXT.CLIENT && {
                params: { client: selectedClientId },
                query: appendQueryParams(item.name),
              })
                || (item.context === CONTEXT.CORPORATE && {
                  params: { corporate: selectedCorporateId },
                  query: appendQueryParams(item.name) }),
            }"
            :title="item.title"
            :value="item.value"
            :active="navItem === item.name"
            :disabled="item.disabled"
            class="ml-n5 text-medium-emphasis"
            color="huntergreen"
          />
        </template>
      </v-list-group>
    </template>

    <v-divider
      v-if="showProgramSetupDivider"
      thickness="1"
    />
    <v-list-subheader
      v-if="showProgramSetupDivider"
      color="grey17"
    >
      Your Programs
    </v-list-subheader>

    <template v-for="nav in getActiveItems(programSetup)">
      <v-list-item
        v-if="!nav.listGroup"
        :key="nav.value"
        :class="nav.name === route.name ? 'readonly-list-item' : ''"
        :to="{
          name: nav.name as string,
          ...(nav.context === CONTEXT.CLIENT && {
            params: { client: selectedClientId },
            query: appendQueryParams(nav.name),
          })
            || (nav.context === CONTEXT.CORPORATE && {
              params: { corporate: selectedCorporateId },
              query: appendQueryParams(nav.name) }),
        }"
        :title="nav.title"
        :value="`${nav.value}-${getRandomIntInclusive(1, 1000)}`"
        :active="navItem === nav.name"
        :disabled="nav.disabled"
        color="huntergreen"
      >
        <template #prepend>
          <v-icon
            size="small"
            :icon="nav.icon"
            class="mr-n4"
          />
        </template>
      </v-list-item>
      <v-list-group
        v-else-if="nav.listGroup && !nav.listGroupItem"
        :key="nav.listGroup"
      >
        <template #activator="{ props }">
          <v-list-item
            v-bind="props"
            :title="nav.title"
            :value="`${nav.value}-${getRandomIntInclusive(1, 1000)}`"
            :active="navItem === nav.name"
            :disabled="nav.disabled"
            color="huntergreen"
          >
            <template #prepend>
              <v-icon
                size="small"
                :icon="nav.icon"
                class="mr-n4"
              />
            </template>
          </v-list-item>
        </template>

        <template
          v-for="item in getSubGroupItems(programSetup, nav.listGroup)"
          :key="item.value"
        >
          <v-list-item
            :class="navItem === item.name ? 'readonly-list-item' : ''"
            :to="{
              name: item.name as string,
              ...(item.context === CONTEXT.CLIENT && {
                params: { client: selectedClientId },
                query: appendQueryParams(item.name),
              })
                || (item.context === CONTEXT.CORPORATE && {
                  params: { corporate: selectedCorporateId },
                  query: appendQueryParams(item.name) }),
            }"
            :title="item.title"
            :value="item.value"
            :active="navItem === item.name"
            :disabled="item.disabled"
            class="ml-n5 text-medium-emphasis"
            color="huntergreen"
          />
        </template>
      </v-list-group>
    </template>

    <v-divider
      v-if="showMerchantSetupDivider"
      thickness="1"
    />
    <v-list-subheader
      v-if="showMerchantSetupDivider"
      color="grey17"
    >
      Your Merchants
    </v-list-subheader>
    <template v-for="nav in getActiveItems(merchantSetup)">
      <v-list-item
        v-if="!nav.listGroup"
        :key="nav.value"
        :class="nav.name === route.name ? 'readonly-list-item' : ''"
        :to="{
          name: nav.name as string,
          ...(nav.context === CONTEXT.CLIENT && {
            params: { client: selectedClientId },
            query: appendQueryParams(nav.name),
          })
            || (nav.context === CONTEXT.CORPORATE && {
              params: { corporate: selectedCorporateId },
              query: appendQueryParams(nav.name) }),
        }"
        :title="nav.title"
        :value="`${nav.value}-${getRandomIntInclusive(1, 1000)}`"
        :active="navItem === nav.name"
        :disabled="nav.disabled"
        color="huntergreen"
      >
        <template #prepend>
          <v-icon
            size="small"
            :icon="nav.icon"
            class="mr-n4"
          />
        </template>
      </v-list-item>
      <v-list-group
        v-else-if="nav.listGroup && !nav.listGroupItem"
        :key="nav.listGroup"
      >
        <template #activator="{ props }">
          <v-list-item
            v-bind="props"
            :title="nav.title"
            :value="`${nav.value}-${getRandomIntInclusive(1, 1000)}`"
            :active="navItem === nav.name"
            :disabled="nav.disabled"
            color="huntergreen"
          >
            <template #prepend>
              <v-icon
                size="small"
                :icon="nav.icon"
                class="mr-n4"
              />
            </template>
          </v-list-item>
        </template>

        <template
          v-for="item in getSubGroupItems(merchantSetup, nav.listGroup)"
          :key="item.value"
        >
          <v-list-item
            :class="navItem === item.name ? 'readonly-list-item' : ''"
            :to="{
              name: item.name as string,
              ...(item.context === CONTEXT.CLIENT && {
                params: { client: selectedClientId },
                query: appendQueryParams(item.name),
              })
                || (item.context === CONTEXT.CORPORATE && {
                  params: { corporate: selectedCorporateId },
                  query: appendQueryParams(item.name) }),
            }"
            :title="item.title"
            :value="item.value"
            :active="navItem === item.name"
            :disabled="item.disabled"
            class="ml-n5 text-medium-emphasis"
            color="huntergreen"
          />
        </template>
      </v-list-group>
    </template>

    <v-divider
      v-if="showclientSettingsDivider"
      thickness="1"
      class="mb-1"
    />

    <template v-for="nav in getActiveItems(settingsItems)">
      <v-list-item
        v-if="!nav.listGroup"
        :key="nav.value"
        :class="nav.name === route.name ? 'readonly-list-item' : ''"
        :to="{
          name: nav.name as string,
          ...(nav.context === CONTEXT.CLIENT && {
            params: { client: selectedClientId },
            query: appendQueryParams(nav.name),
          })
            || (nav.context === CONTEXT.CORPORATE && {
              params: { corporate: selectedCorporateId },
              query: appendQueryParams(nav.name) }),
        }"
        :title="nav.title"
        :value="`${nav.value}-${getRandomIntInclusive(1, 1000)}`"
        :active="navItem === nav.name"
        :disabled="nav.disabled"
        color="huntergreen"
      >
        <template #prepend>
          <v-icon
            size="small"
            :icon="nav.icon"
            class="mr-n4"
          />
        </template>
      </v-list-item>
      <v-list-group
        v-else-if="nav.listGroup && !nav.listGroupItem"
        :key="nav.listGroup"
      >
        <template #activator="{ props }">
          <v-list-item
            v-bind="props"
            :title="nav.title"
            :value="`${nav.value}-${getRandomIntInclusive(1, 1000)}`"
            :active="navItem === nav.name"
            :disabled="nav.disabled"
            color="huntergreen"
          >
            <template #prepend>
              <v-icon
                size="small"
                :icon="nav.icon"
                class="mr-n4"
              />
            </template>
          </v-list-item>
        </template>

        <template
          v-for="item in getSubGroupItems(settingsItems, nav.listGroup)"
          :key="item.value"
        >
          <v-list-item
            :class="navItem === item.name ? 'readonly-list-item' : ''"
            :to="{
              name: item.name as string,
              ...(item.context === CONTEXT.CLIENT && {
                params: { client: selectedClientId },
                query: appendQueryParams(item.name),
              })
                || (item.context === CONTEXT.CORPORATE && {
                  params: { corporate: selectedCorporateId },
                  query: appendQueryParams(item.name) }),
            }"
            :title="item.title"
            :value="item.value"
            :active="navItem === item.name"
            :disabled="item.disabled"
            class="ml-n5 text-medium-emphasis"
            color="huntergreen"
          />
        </template>
      </v-list-group>
    </template>

    <v-divider
      v-if="showAdminDivider"
      thickness="1"
    />
    <v-list-subheader
      v-if="showAdminDivider"
      color="grey17"
    >
      Admin
    </v-list-subheader>

    <template v-for="nav in getActiveItems(admin)">
      <v-list-item
        v-if="!nav.listGroup"
        :key="nav.value"
        :class="nav.name === route.name ? 'readonly-list-item' : ''"
        :to="{
          name: nav.name as string,
          ...(nav.context === CONTEXT.CLIENT && {
            params: { client: selectedClientId },
            query: appendQueryParams(nav.name),
          })
            || (nav.context === CONTEXT.CORPORATE && {
              params: { corporate: selectedCorporateId },
              query: appendQueryParams(nav.name) }),
        }"
        :title="nav.title"
        :value="`${nav.value}-${getRandomIntInclusive(1, 1000)}`"
        :active="navItem === nav.name"
        :disabled="nav.disabled"
        color="huntergreen"
      >
        <template #prepend>
          <v-icon
            size="small"
            :icon="nav.icon"
            class="mr-n4"
          />
        </template>
      </v-list-item>
      <v-list-group
        v-else-if="nav.listGroup && !nav.listGroupItem"
        :key="nav.listGroup"
      >
        <template #activator="{ props }">
          <v-list-item
            v-bind="props"
            :title="nav.title"
            :value="`${nav.value}-${getRandomIntInclusive(1, 1000)}`"
            :active="navItem === nav.name"
            :disabled="nav.disabled"
            color="huntergreen"
          >
            <template #prepend>
              <v-icon
                size="small"
                :icon="nav.icon"
                class="mr-n4"
              />
            </template>
          </v-list-item>
        </template>

        <template
          v-for="item in getSubGroupItems(admin, nav.listGroup)"
          :key="item.value"
        >
          <v-list-item
            :class="navItem === item.name ? 'readonly-list-item' : ''"
            :to="{
              name: item.name as string,
              ...(item.context === CONTEXT.CLIENT && {
                params: { client: selectedClientId },
                query: appendQueryParams(item.name),
              })
                || (item.context === CONTEXT.CORPORATE && {
                  params: { corporate: selectedCorporateId },
                  query: appendQueryParams(item.name) }),
            }"
            :title="item.title"
            :value="item.value"
            :active="navItem === item.name"
            :disabled="item.disabled"
            class="ml-n5 text-medium-emphasis"
            color="huntergreen"
          />
        </template>
      </v-list-group>
    </template>
  </v-list>
</template>

<style lang="scss" scoped>
.v-list--nav .v-list-subheader {
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 700;
  font-family: 'Public Sans', sans-serif;
}

.readonly-list-item {
  pointer-events: none;
}
</style>
