import type { Ref } from 'vue'
import type { IAccount, IAccountDepositRequest, IAccountRequestParams, IDepositResult } from '~/types/account.types'
import type { IOliveError } from '~/types/errors.types'
import type { IPlatformResponse } from '~/types/platform.types'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { HTTP_METHOD } from '~/types/common.types'
import { OLIVE_ERROR_CODE } from '~/types/errors.types'

export const useAccountsStore = defineStore('accounts', () => {
  const oliveBaseUrl = '/accounts'

  // #region state
  const accounts = ref<IAccount[]>([])
  const accountIdsCurrentPage = ref<string[]>([])
  const numberOfAccounts = ref<number | undefined>()
  const error = ref<IOliveError>(getOliveError())
  const isLoading = ref(false)
  const filter = ref<IAccountRequestParams>({})

  const latestRequestTime = ref()
  // #endregion

  // #region getters
  const getAccountById = computed(() => {
    return (id: string) => accounts.value.find(a => a.id === id)
  })

  const getAccountsByClientId = computed(() => {
    return (clientId: string) => accounts.value.filter(a => a.clientId === clientId)
  })

  const getAccountsCurrentPage = computed(() => {
    return accounts.value.filter(a => accountIdsCurrentPage.value.includes(a.id))
  })

  const getAccountByCorporateId = computed(() => {
    return (corporateId: string) => accounts.value.find(a => a.corporateId === corporateId)
  })
  // #endregion

  // #region actions
  const loadAccounts = async (params?: IAccountRequestParams | Ref<IAccountRequestParams>) => {
    const {
      response,
      error: accountError,
      run: loadAccounts,
    } = useOliveAPI<IPlatformResponse<IAccount>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Error loading accounts',
    })
    isLoading.value = true
    error.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadAccounts()

    if (response.value && !accountError.value.hasError) {
      accounts.value = useArrayUnique([...response.value.items, ...accounts.value], (a, b) => a.id === b.id).value

      if (latestRequestTime.value === currentRequestDateTime) {
        accountIdsCurrentPage.value = [...response.value.items.map(a => a.id)]
        numberOfAccounts.value = response.value.totalNumberOfRecords
      }
    }
    else { error.value = accountError.value }

    isLoading.value = false
  }

  const loadAccount = async (accountId: string) => {
    if (getAccountById.value(accountId))
      return

    const {
      response,
      error: accountError,
      run: loadAccount,
    } = useOliveAPI<IAccount>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${accountId}`,
      errorMessage: 'Error loading account',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadAccount()

    if (response.value && !accountError.value.hasError)
      accounts.value = useArrayUnique([response.value, ...accounts.value], (a, b) => a.id === b.id).value
    else
      error.value = accountError.value

    isLoading.value = false
  }

  const depositIntoAccount = async (accountId: string, accountRecharge: IAccountDepositRequest) => {
    if (!getAccountById.value(accountId)) {
      error.value = getOliveError(true, OLIVE_ERROR_CODE.Entity_Not_Loaded, { title: 'Account not loaded' })
      return
    }

    const {
      response,
      error: accountError,
      run: depositIntoAccount,
    } = useOliveAPI<IDepositResult>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${accountId}/deposit`,
      data: accountRecharge,
      errorMessage: 'Error depositing into account',
    })
    isLoading.value = true
    error.value = getOliveError()

    await depositIntoAccount()

    if (response.value && !accountError.value.hasError) {
      if (!response.value.paymentMethodTransaction.success) {
        error.value = getOliveError(true, OLIVE_ERROR_CODE.UnspecifiedValidationFailure, { title: 'Error', text: response.value.paymentMethodTransaction.message })
      }
      else {
        const account = getAccountById.value(accountId)
        if (account)
          account.balance = response.value.accountBalance
      }
    }
    else {
      error.value = accountError.value
    }

    isLoading.value = false
    return response
  }

  const clearCurrentPage = () => {
    accountIdsCurrentPage.value = []
  }

  const clearFilter = () => {
    filter.value = {}
  }
  // #endregion

  return {
    accounts,
    numberOfAccounts,
    error,
    isLoading,
    filter,
    getAccountById,
    getAccountsByClientId,
    getAccountByCorporateId,
    getAccountsCurrentPage,
    depositIntoAccount,
    loadAccounts,
    loadAccount,
    clearCurrentPage,
    clearFilter,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAccountsStore as any, import.meta.hot))
